import React from 'react'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";
import { BsInstagram } from "react-icons/bs";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Harinderpal Singh</h3>
        <p className="text-lg font-normal text-gray-400">
          Full Stack Developer
        </p>
        <p className="text-base text-gray-400 tracking-wide">
        <i>Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful."</i> <b>- Albert Schweitzer</b>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+918054652775</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">Hsbhular@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
          <a href='https://www.facebook.com/harinderpal.singhbhullar.5' target='_blank'><span className="bannerIcon">
            <FaFacebookF />
          </span></a>
         <a href='https://www.instagram.com/harinder.bhular/' target='_blank'> <span className="bannerIcon">
            <BsInstagram />
          </span></a>
         <a href='https://www.linkedin.com/in/harinderpal-singh-8695a4101/' target='_blank'> <span className="bannerIcon">
            <FaLinkedinIn />
          </span></a>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft