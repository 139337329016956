import {  AiFillApi} from "react-icons/ai";
import { FaMobile, FaGlobe} from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import { SiGocd } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <AiFillApi />,
    title: "RESTful API Development",
    des: "I specialize in designing and developing RESTful APIs that power modern web applications. I create endpoints that enable seamless communication between front-end and back-end systems. ",
  },
  {
    id: 2,
    icon: <SiGocd />,
    title: "Efficient Backend Solutions",
    des: "My expertise extends to building robust and scalable backend systems using technologies like Laravel and PHP. I ensure that the server-side functionality supports the application's frontend.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "Responsive Web Design",
    des: "I craft responsive websites that adapt gracefully to different screen sizes and devices. By combining HTML, CSS, and React.js, I create websites that offer consistent and optimal user experiences.   ",
  },
  
];
