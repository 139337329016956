import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from './Media';
import './cv.css'

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Front-End Developer.", "Back-End Developer.", "PHP Laravel Developer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Harinderpal</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          I am a Web Developer based in India. I have rich experience in web
          site design &amp; building and customization. Also I am good at
          <span className="text-designColor"> Laravel</span> and
          <span className="text-designColor"> React js</span>.
        </p>
        <a className="bg-gray-300 cv hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" href="hsb.pdf" target="_blank" rel="noopener noreferrer">
  <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
  </svg>
  Resume
</a>

      </div>

      <div>
        
      </div>
     {/* Media */}
     <Media />
    </div>
  );
}

export default LeftBanner