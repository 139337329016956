import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]"> </p>
          <h2 className="text-3xl md:text-4xl font-bold">Education Quality</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Secondary School Education (12th)"
            subTitle="Kingstar Secondary School (2012 - 2013)"
            result="5.00/5"
            des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          />
          <ResumeCard
            title="BSc in Computer Science"
            subTitle="Global institute of technology (2013 - 2017)"
            result="3.90/4"
            des="During my time at the University of DVI, I achieved an impressive academic record with a GPA of 3.90/4. This program provided comprehensive training, equipping me with the skills and knowledge necessary to excel in various sectors of the economy and contribute to cultural development."
          />
          <ResumeCard
            title="Web Development Training"
            subTitle="NSPL Institute"
            result="5.00/5"
            des="At NSPL Institute, we understand that web development is a rapidly evolving field. Our training program is designed to equip  with the latest tools, technologies, and techniques to stay ahead in this competitive industry. Here's what you can expect."
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]"></p>
          <h2 className="text-3xl md:text-4xl font-bold">Skills Quality</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
  
          <ResumeCard
            title="Front-end Developer"
            subTitle="HTML, CSS, BOOTSTRAP, REACT JS (2021 - 2022)"
            result="IND"
            des="As a Front-end Developer, I spearheaded the development of visually stunning and responsive web interfaces utilizing HTML, CSS, and Bootstrap. My expertise in React.js infused interactivity and innovation into projects."
          />

          <ResumeCard
            title="Back-end Developer"
            subTitle="PHP,LARAVEL,MYSQL (June 2022 - May 2023)"
            result="IND"
            des="As a Back-end Developer, I specialized in PHP, Laravel, and MySQL, crafting robust server-side solutions. My contributions played a pivotal role in project success, reflecting a commitment to excellence and collaborative problem-solving. Join me in shaping the future of technology-driven endeavors."
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Education