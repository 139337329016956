import React from 'react';
import './footer.css'

const Footer = () => {
  return (
    <div className="flex flex-col lg:flex-row mt-3">
      <div className="lg:w-1/2">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 mxn lg:max-w-none">
            <div className="max-w-xl lg:max-w-sm lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Subscribe to our newsletter.</h2>
             
              <div className="mt-6 mtred flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only ">Email address</label>
                <input  id="email-address" name="email" type="email" autoComplete="email" required className="subs min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email "/>
                <button type="submit" className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 subs">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="lg:w-1/2">
        {/* Map Section */}
        <div className="lg:w-2/3 map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27296.124708037343!2d74.62524622981458!3d31.2202975093699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391997e893d56eab%3A0xfed325412223faac!2sHarinder%20bhullar%20farm!5e0!3m2!1sen!2sin!4v1693392506163!5m2!1sen!2sin"
           max-width="100%" height="300" allowFullScreen=""
            loading="lazy" title="map" style={{ minHeight: '200px' ,borderRadius: "10px" }}>
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default Footer;
