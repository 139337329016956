import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 both border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
      <ProjectsCard
          title={
            <a href='https://covidgeo.netlify.app' target='_blank'>
              API BASED WEBSITE
            </a>
          }
          des="This is a API based website. I have used React js and BootStrap.The website based on Daily Weather and CoronaTracker. "
          src={projectOne}
        />
        <ProjectsCard
        
          title="E-commerce Website"
          des=" This is a E-commerce website. I have used React js and BootStrap. The website based is frontend with backend."
          src={projectTwo}
        />
        <ProjectsCard
          title={<a href='https://tvflex.netlify.app/' target='_blank'>  "MOVIES WEBSITE"  </a>}
          des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          src={projectThree}
        />
    
      
      </div>
    </section>
  );
}

export default Projects