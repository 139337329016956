import React from 'react'
import { bannerImg } from "../../assets/index";
import "./right.css";

const RightBanner = () => {
  return (
    <div className=" bg-gradient-to-r  from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne  shd flex flex-col md:flex-row gap-6 md:justify-start lgl:justify-center ml-5">
      <img
        className="img-fluid img-responsive w-full h-full object-cover rounded-lg"
        src='prof.jpg'
        alt="bannerImg" 
      />
      <div className="absolute  flex justify-center items-center"></div>
    </div>
  );
}

export default RightBanner